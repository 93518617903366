'use client';

import { useRouter } from 'next/navigation';
import styled from 'styled-components';

import Button from '@/components/UI/Button';
import { color, devices } from '@/styles/variables';

const Container = styled.div`
	background-color: ${color.bgColor};
	padding: 80px 0;
	text-align: center;
`;

const Title = styled.h1`
	color: #232323;
	font-size: 72px;
	@media ${devices.mobile} {
		font-size: 96px;
	}
`;

const Content = styled.p`
	font-size: 18px;
	margin: 32px 0;
	@media ${devices.mobile} {
		font-size: 20px;
	}
`;

const NotFound = () => {
	const router = useRouter();

	return (
		<Container>
			<Title>404</Title>
			<Content>Oops! There is NOTHING here... </Content>
			<Button onClick={() => router.back()}>Back</Button>
		</Container>
	);
};
export default NotFound;
